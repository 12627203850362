<template>
  <v-snackbar
    v-model="internalValue"
    :timeout="timeout"
    class="v-snackbar--material"
    v-bind="{
      ...$attrs,
      'color': 'transparent'
    }"
  >
    <div class="v-snackbar__box">
      <material-alert
        v-model="internalValue"
        :color="$attrs.color"
        :dismissible="dismissible"
        :type="type"
        class="ma-0"
        dark
      >
        <slot />
      </material-alert>
    </div>
  </v-snackbar>
</template>

<script>
  export default {
    name: 'MaterialSnackbar',

    props: {
      dismissible: {
        type: Boolean,
        default: true,
      },
      type: {
        type: String,
        default: '',
      },
      value: Boolean,
      timeout: {
        type: [String, Number],
      },
    },

    data () {
      return {
        internalValue: this.value,
      }
    },

    watch: {
      internalValue (val, oldVal) {
        if (val === oldVal) return

        this.$emit('input', val)
      },
      value (val, oldVal) {
        if (val === oldVal) return

        this.internalValue = val
      },
    },
  }
</script>

<style lang="sass">
  .v-snackbar
    &--material
      margin-top: 32px
      margin-bottom: 32px

      .v-snackbar
        &__box
          max-height: 400px
          overflow-y: auto

      .v-alert
        padding: 32px 16px

      .v-alert--material,
      .v-snack__wrapper
        border-radius: 4px

      .v-snack__content
        overflow: visible
        padding: 0

      .v-snack__action
        display: none

      .v-btn
        align-self: start
</style>
