<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
  // Styles
  import '@/styles/overrides.sass'
  import '@/styles/main.css'
  import {
    mapGetters
} from 'vuex'

  export default {
    name: 'App',
    metaInfo: {
      title: 'App',
      titleTemplate: "Юг-Контракт | адміністрування",
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
    computed: {
      ...mapGetters(['snackbar'])
    },
    created() {
        (async () => {
            await this.$recaptchaLoaded()
            const recaptcha = this.$recaptchaInstance
            // Hide reCAPTCHA badge:
            recaptcha.hideBadge()
        })()
    },
  }
</script>
