<template>
  <!-- 
    Диалоговое окно для удаляения
    Для использования нужно его вызвать в async функции, 
    если пользователь польватель нажал Да - промис вернёт true, если Нет false
    Пример:
    async deleteActionLot() {
      let isConfirm = await this.$refs.dialogConfitmDelete.callDialog();
      if (!isConfirm) return;
      ....
    }
  -->
  <v-dialog
    v-model="isShow" max-width="400">
    <v-card>
      <v-card-title class="text-h5 align-center">Ви дійсно хочете видалити?</v-card-title>
      <v-card-actions class="flex justify-center">
        <v-btn
          color="primary" 
          min-width="150"
          text
          @click="actionDialog(true)"
        >
          Так
        </v-btn>
        <v-btn
          color="grey"
          min-width="150"
          text
          @click="actionDialog(false)"
        >
          Нi
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>                         
</template>

<script>
export default {
  name: 'DialogConfitmDelete',
  data() {
    return {
      isShow: false,
      resolve: null
    }
  },

  methods: {
    // Вызвать диалоговое окно в родительском компоненте через $refs
    callDialog() {
      this.isShow = true;
      return new Promise((resolve, reject) => {
          this.resolve = resolve;
      });
    },
    // Если пользователь польватель нажал Да - промис вернёт true, если Нет false
    actionDialog(action) {
      this.resolve(action);
      this.isShow = false;
      this.resolve = null;
    },
  },
}
</script>

<style lang="sass">
</style>
