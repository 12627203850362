import axios from 'axios'
import { AUTH_BASE_URL, DATA_BASE_URL, CHECKOUT_BASE_URL } from './env.config'
import store from '@/store/index'

let refreshRequest = null
let isAlreadyFetchingAccessToken = false
let counterErrors401 = 0;
let timeStartErrors401 = 0;

const HTTP = axios.create({
    baseURL: DATA_BASE_URL,
})

const HTTP_AUTH = axios.create({
    baseURL: AUTH_BASE_URL,
})

const CHECKOUT = axios.create({
    baseURL: CHECKOUT_BASE_URL,
})


function logOut() {
    store.commit('logOut');
    window.location = '/login/';
}


// Add a request interceptor
HTTP.interceptors.request.use(config => {
        const authToken = localStorage.authToken ? JSON.parse(localStorage.authToken) : ''
        
        if (!authToken) {
            return config
        } else {
            const newConfig = {
                headers: {},
                ...config
            }
            newConfig.headers.Authorization = `Bearer ${authToken}`
            return newConfig;
        }

    },
    (error) => Promise.reject(error)
);
HTTP_AUTH.interceptors.request.use(config => {
        const authToken = localStorage.authToken ? JSON.parse(localStorage.authToken) : ''
        
        if (!authToken) {
            return config
        } else {
            const newConfig = {
                headers: {},
                ...config
            }
            newConfig.headers.Authorization = `Bearer ${authToken}`
           
            return newConfig;
        }

    },
    (error) => Promise.reject(error)
);

// Add a response interceptor
HTTP.interceptors.response.use(async response => {
    return response;
}, async error => {
    let isStatus401 = error?.response?.status == 401;

    // Обработка ошибки 401 если она много раз повторяется на коротком промежутке времени (при неправильном ответе от сервера)
    if (Date.now() - timeStartErrors401 > 10000) {
        counterErrors401 = 0;
        timeStartErrors401 = 0;
    }
    if (counterErrors401 >= 4) return Promise.reject();

    if (isStatus401) {
        counterErrors401++;
        if (!timeStartErrors401) {
            timeStartErrors401 = Date.now();
        }
    }

    const originalRequest = error.config
    let authToken = localStorage.authToken ? JSON.parse(localStorage.authToken) : ''
    let refreshToken = localStorage.refreshToken ? JSON.parse(localStorage.refreshToken) : ''

    if (isStatus401 && !isAlreadyFetchingAccessToken && refreshToken) {
        try {
            isAlreadyFetchingAccessToken = true
            if (!refreshRequest) {
                refreshRequest = HTTP_AUTH.post("/auth/refresh", {
                    refreshToken: refreshToken
                })
            }
            //Принимаем новую пару токенов
            const {
                data
            } = await refreshRequest
    
            if (data.content.token && data.content.refreshToken) {
                authToken = data.content.token
                refreshToken = data.content.refreshToken
                localStorage['authToken'] = JSON.stringify(authToken)
                localStorage['refreshToken'] = JSON.stringify(refreshToken)
                originalRequest.headers.Authorization = `Bearer ${authToken}`
                refreshRequest = null
                isAlreadyFetchingAccessToken = false
            }
            return HTTP(originalRequest)
        } catch(error) {
            if (isStatus401) {
                logOut();
            }
            return error;
        }
    } else if(isStatus401) {
        logOut();
    } else {
        return Promise.reject(error)
    }

})

// Add a response interceptor
HTTP_AUTH.interceptors.response.use(async response => {
    return response;
}, async error => {
    let isStatus401 = error?.response?.status == 401;
    const originalRequest = error.config
    let authToken = localStorage.authToken ? JSON.parse(localStorage.authToken) : ''
    let refreshToken = localStorage.refreshToken ? JSON.parse(localStorage.refreshToken) : ''

    if (isStatus401 && !isAlreadyFetchingAccessToken && refreshToken) {
        try {
            isAlreadyFetchingAccessToken = true
            if (!refreshRequest) {
                refreshRequest = HTTP_AUTH.post("/auth/refresh", {
                    refreshToken: refreshToken
                })
            }
            //Принимаем новую пару токенов
            const {
                data
            } = await refreshRequest
    
            if (data.content.token && data.content.refreshToken) {
                authToken = data.content.token
                refreshToken = data.content.refreshToken
                localStorage['authToken'] = JSON.stringify(authToken)
                localStorage['refreshToken'] = JSON.stringify(refreshToken)
                originalRequest.headers.Authorization = `Bearer ${authToken}`
                refreshRequest = null
                isAlreadyFetchingAccessToken = false
            }
            return HTTP_AUTH(originalRequest)
        } catch(error) {
            if (isStatus401) {
                logOut();
            }
            return error;
        }
    } else {
        return Promise.reject(error)
    }
})

export {
    HTTP,
    HTTP_AUTH,
    CHECKOUT
}
