import Vue from 'vue';

Vue.filter('formatDate', (value) => {
  if (value) {
    return String(value).slice(0,10).replace(/-/g,'/').split('/').reverse().join('/')
  }
});

Vue.filter('formatDateTime', (value) => {
  if (value) {
    const date = String(value).slice(0,10).replace(/-/g,'/').split('/').reverse().join('/')
    const time = String(value).split('T')[1].slice(0,8)

    return `${date} ${time}`
  }
});

Vue.filter('numFormat', (value) => {
  if (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
});