// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import { trailingSlash } from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/Login.vue'),
    },
    layout('Default', [
      route('Dashboard'),
      route('Users', null, '/users'),
      route('UserAdd', null, '/users/add'),
      route('UserProfile', null, '/users/:id'),
      route('Orders', null, '/orders'),
      route('OrderProfile', null, '/orders/:id'),
      route('Preorders', null, '/preorders'),
      route('PreorderProfile', null, '/preorders/:id'),
      route('Actions', null, '/actions'),
      route('ActionAdd', null, '/actions/add'),
      route('ActionProfile/ActionProfile', null, '/actions/:id'),
      route('ActionType', null, '/action-type'),
      route('ActionTypeAdd', null, '/action-type/add'),
      route('ActionTypeProfile', null, '/action-type/:id'),
      route('Icons', null, '/export_to_excel_orders'),
      route('Regular Tables', null, '/export_to_excel_preorders'),
      route('Google Maps', null, 'maps/google'),
      route('Brands', null, '/brands'),
      route('Catalogs', null, '/catalogs'),
      route('SiteBrands', null, '/site-brands'),
      route('Catalogs', null, '/catalogs/:id'),
      route('BrandProfile', null, '/brands/:id'),
      route('Page403', null, '/403'),
      route('Page500', null, '/500'),
      route('Page404', null, '*'),
    ]),
  ],
})

// История для кнопки ButtonComeBack
function setStoreComeBack(to, from) {
  let storeComeBack = JSON.parse(window.sessionStorage.getItem('storeComeBack')|| '{}');
  // Первая загрузка / обновление страницы
  let isInit = !from.name || !from.matched[1] || !to.matched[1];
  // Указывает на то что пользователь пришёл на страницу где есть кнопка назад (страница больше 1-го уровня)
  let isShowComeBackBtn = to.path.split('/').filter(el => !!el).length > 1;
  // Указывает на то что пользователь переходит по ссилкам одно типа страниц (/actions/:id)
  let isSameTypePage = from.matched[1] && to.matched[1] && from.matched[1].path === to.matched[1].path;
  // Урл первого уровня ОТ
  let pathFirstLevelFrom = from.path.split('/')[1];
  // Урл первого уровня НА
  let pathFirstLevelTo = to.path.split('/')[1];
  // Указывает на то что пользователь меняет раздел
  let isСhangeSection = (pathFirstLevelFrom && pathFirstLevelTo) && (pathFirstLevelFrom !== pathFirstLevelTo);

  // Если пользователь переходит по страницам одного типа
  if (isSameTypePage) return;

  // Чистим storeComeBack если пользователь пришёл на страницу где нет кнопки 
  if (!isShowComeBackBtn) {
    window.sessionStorage.setItem('storeComeBack', '{}');
    storeComeBack = {};
    return;
  }

  // Если человек переходит в другой раздел, чистим storeComeBack
  if (isСhangeSection) {
    window.sessionStorage.setItem('storeComeBack', '{}');
    storeComeBack = {};
  };

  if (!isInit) {
    let regExpMovingForward = new RegExp(`^${from.matched[1].path}`, 'g');
    let regExpMovingBack = new RegExp(`^${to.matched[1].path}(?=[^\s])`, 'g');
    let isMovingForward = regExpMovingForward.test(to.matched[1].path);
    let isMovingBack = regExpMovingBack.test(from.matched[1].path);
    
    // Когда пользователь переходит на вложенную страницу, добавляем в историю
    if (isMovingForward) {
      storeComeBack[to.matched[1].path] = from.fullPath;
    }

    // Удаляем ссылку на при движении назад
    if (isMovingBack) {
      for(let key in storeComeBack) {
        if (regExpMovingBack.test(key)) {
          delete storeComeBack[key];
        }
      }
    }
    window.sessionStorage.setItem('storeComeBack', JSON.stringify(storeComeBack));
  };

  // Если странице должна быть кнопка назад, но в store нет ссылки - ставим ссылку на 1-й уровень
  if (isShowComeBackBtn && !storeComeBack[to.matched[1].path]) {
    storeComeBack[to.matched[1].path] = `/${pathFirstLevelTo}/`;
    window.sessionStorage.setItem('storeComeBack', JSON.stringify(storeComeBack));
  }
};

router.beforeEach((to, from, next) => {

  setStoreComeBack(to, from);

  if (to.name != 'login' && to.name != '404' && to.name != '403') {

    /*
      referrerPath
      Перенаправить пользователя со страницы login, 
      на ту страницу на которую он шол изначально
      или на ту где разлогинился
    */ 
    window.sessionStorage.setItem('referrerPath', to.fullPath);

    store.dispatch('getUserInfo')
      .then(data => {
        if (data.status === 401) {
          document.title = 'Вхiд'
        } else if(data.status === 500) {
          next('/500')
        } else {
          to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
        }
      })
      .catch(err => {
        console.log(err)
        next('/login')
      })
  } else {
    to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
  }
})

export default router
