<template>
  <v-btn v-if="url" color="gray" :to="url">
    <v-icon left>mdi-arrow-left</v-icon>
    Повернутися
  </v-btn>                        
</template>

<script>

export default {
  name: 'ButtonComeBack',
  data() {
    return {
      url: '',
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        let matchedPath = to.matched[1] && to.matched[1].path;
        let storeComeBack = JSON.parse(window.sessionStorage.getItem('storeComeBack')|| '{}');
        this.url = storeComeBack[matchedPath] ? storeComeBack[matchedPath] : '';
      },
      deep: true,
      immediate: true,
    }
  },
}
</script>

<style lang="sass">
</style>
