import {
  HTTP,
  HTTP_AUTH
} from '../../http-common'

const SNACKBAR_TIMEOUT = 1000;

export default {
  state: {
    projects: [],
    roles: [
      {
        id: 1,
        name: "Admin"
      },
      {
        id: 2,
        name: "Manager"
      },
    ],
    snackbar: {
      model: false,
      type: '',
      text: '',
      timeout: SNACKBAR_TIMEOUT
    },
    status: [],
    types: [
      {
        id: 1,
        'name': 'f5.ua',
      },
      {
        id: 2,
        'name': 'Yug24/7',
      },
      {
        id: 3,
        'name': 'КЗ F5',
      },
    ],
    delivery: [
      {
        id: 1,
        name: 'Доставка',
      },
      {
        id: 2,
        name: 'Самовивіз з центру видачі Нової Пошти',
      },
      {
        id: 3,
        name: 'Торгова точка f5'
      },
    ],
    modules: [],
    deviceId: null,
    userInfo: {},
    userRole: null,
    localization: [] 
  },
  mutations: {
    setUserInfo(state, payload) {
        state.userInfo = payload
    },
    setUserRole(state, payload) {
        if (payload.roles && payload.roles.length) {
            state.userRole = payload.roles[0].name || null;
        }
    },
    setDeviceId(state, payload) {
        const parsed = JSON.stringify(state.viewedProducts);

        localStorage.setItem('deviceId', parsed);
    },
    setSnackbar(state, { type, text, timeout = SNACKBAR_TIMEOUT }) {
      state.snackbar.type = type.toString()
      state.snackbar.text = text
      state.snackbar.model = true
      state.snackbar.timeout = type === 'error' ? -1 : timeout;
      return new Promise((resolve, reject) => {
        if (state.snackbar.model === false) resolve();
      });
    },
    setProjects(state, payLoad) {
        state.projects = payLoad
    },
    setStatus(state, payLoad) {
        state.status = payLoad
    },
    setModules(state, payLoad) {
        if (payLoad && payLoad.length) {
            payLoad.forEach(el => {
                el.value = [];
            }) 
        }

        state.modules = payLoad
    },
    setLocalization(state, payLoad) {
      state.localization = payLoad
    },

    logOut() {
        localStorage.removeItem("authToken")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("deviceId")
        this.commit('setUserInfo', { id: 0 })
    },
  },
  actions: {
    getUserInfo({ commit, state }) {
        state.userInfoLoading = true
        state.loading = true
        let parsedDeviceId;

        if (localStorage.getItem('deviceId')) {
            parsedDeviceId = JSON.parse(localStorage.getItem('deviceId'));
        }
        
        return new Promise((resolve, reject) => {
            HTTP.post('/user-info', {
                deviceId: parsedDeviceId || null
            })
                .then(response => {
                    state.userInfoLoading = false
                    state.loading = false

                    if (response.data && response.data.content) {
                        commit('setUserInfo', response.data.content)
                        commit('setUserRole', response.data.content)
                        resolve(response.data.content)
                    } else {
                        commit('setUserInfo', {
                            id: 0
                        })
                    }
                })
                .catch(error => {
                    state.userInfoLoading = false
                    state.loading = false
                    commit('setUserInfo', {
                        id: 0
                    })
        
                    if (error.response && error.response.status === 401) {
                        commit('logOut')
                        resolve(error.response)
                    } else {
                        state.error = true
                        reject(error)
                    }
                })
        })
    },
    login({ state, commit }, data) {
        state.userInfoLoading = true
        state.loading = true
        return new Promise((resolve, reject) => {
            HTTP_AUTH.post('/auth/login', data)
                .then(response => {
                    state.userInfoLoading = false
                    state.loading = false

                    if(response.data.content && response.data.content.deviceId) {
                        commit('setDeviceId', response.data.content.deviceId)
                    }
                    
                    resolve(response.data.content)
                })
                .catch(error => {
                    state.userInfoLoading = false
                    state.loading = false
                    state.error = true
                    reject(error)
                })
        })
    },
    logOut({ commit }) {
        return new Promise((resolve, reject) => {
            HTTP_AUTH.delete('/auth/logout')
                .then(response => {
                    commit('logOut');
                    resolve(response);
                })
                .catch(e => {
                    commit('logOut');
                    console.error(e)
                    reject(e)
                })
        })
    },
    async getProjects({ state, commit }) {
        if (!state.projects.length) {
            try {
                const { data } = await HTTP.post('/get-projects');
                if (data?.content) {
                    commit('setProjects', data.content)
                }
            } catch(error) {
                console.log(error);
            }
        }
    },
    getStatus({ state, commit }) {
        if (!state.projects.length) {
            HTTP.post('/get-status')
                .then(response => {
                    if (response.data && response.data.content) {
                        commit('setStatus', response.data.content)
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }
    },
    getModules({ commit }) {
        HTTP.post('/get-modules')
            .then(response => {
                if (response.data && response.data.content) {
                    commit('setModules', response.data.content)
                }
            })
            .catch(error => {
                console.log(error);
            })
    },
    // Получить локализации
    async getLocalization({ commit }) {
      let localization = [];
      try {
        let { data } = await HTTP.post('/get-list-localization');
        if (data?.content?.localization) {
          localization = data.content.localization;
        }
      } catch(error) {
        console.log(error);
      }
      commit('setLocalization', localization);
      return localization;
    }
  },
  getters: {
      userInfo(state) {
          return state.userInfo
      },
      isAdmin(state) {
          return state.userRole === 'Admin'
      },
      isManager(state) {
          return state.userRole === 'Manager'
      },
      snackbar(state) {
          return state.snackbar
      },
      projects(state) {
          return state.projects
      },
      roles(state) {
          return state.roles
      },
      userModules(state) {
          return state.userInfo.modules
      },
      status(state) {
          return state.status
      },
      types(state) {
          return state.types
      },
      delivery(state) {
          return state.delivery
      },
      modules(state) {
          return state.modules
      },
      localization(state) {
          return state.localization
      },
  }
}