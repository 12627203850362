<template>
  <div class="preloader">
    <v-progress-circular class="preloader__element" color="red" indeterminate></v-progress-circular>
  </div>                              
</template>

<script>
  export default {
    name: 'Preloader',
  }
</script>

<style lang="sass">
.preloader
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 10
  background-color: rgba(255, 255, 255, .5)
  display: flex
  justify-content: center
  align-items: center
  &__element
    flex-shirk: 0
</style>
