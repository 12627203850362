<!--
  Cсылка на загруженное изображение предаётся через v-model
  При изменении input, будет генерироватся событие "image-upload:change", 
  с этим событием будет передаватся функция "sendImage", в эту функцию
  нужно передать объект c данными для запроса (пример { lang_key: "ua", object_image_id: 123, image_type: "actions" })
  и вызвать функцию, функция возвращает промис.

  <ImageUpload v-model="image"  @image-upload:change="changeImage($event)" />

  changeImage(event) {
    //  функцию  event.sendImage  можно гдето сохранить и вызвать когда понадобится
    event.sendImage({ "lang_key": "ua", "object_image_id": 123, image_type: "actions" });
  }
-->
<template>
  <div class="image-upload">
      <!--Превью (при передаче props или загрузке через форму)-->
      <!-- <div>+{{ image }}</div> -->
      <!-- <div>-{{ imageCur }}</div> -->
      <div class="image-upload__box-preview" 
          :style="image || file ? { height: `${maxHeight}px`, width: `${maxWidth}px` } : {}"
      >
        <!--Превью-->
        <v-img 
          v-if="imageCur"
          class="image-upload__preview"
          :max-width="maxWidth"
          :max-height="maxHeight"
          :src="imageCur"
        />

        <!--Кнопка востановить изображение-->
        <v-btn v-if="!file && image"
          class="image-upload__btn-restore"
          title="Повернути зображення"
          @click="restoreImage()">
          <v-icon large>mdi-restore</v-icon>
        </v-btn>
      </div>

      <!--Форма загрузки-->
      <v-file-input
        chips
        :accept="accept"
        :label="label"
        @change="setImage()"
        v-model="file"
        :error-messages="error"
        ref="file"
      />
    <!--Сообщение об ошибке-->
    <Preloader v-if="isSending" />
  </div>                              
</template>

<script>
import { HTTP } from '@/http-common';

export default {
  name: 'ImageUpload',
  data() {
    return {
      // Текущие изображение
      imageCur: "",
      // Ошибка
      error: "",
      // Файл
      file: null,
      // Указывает на то что выполняется запрос
      isSending: false,
      // accept
      accept: "",
    }
  },
  props: {
    label: {
      type: String,
      default: "Зображення"
    },
    // Изображение (передавать через v-model)
    image: {
      type: String,
      default: ""
    },
    // Максимальная ширина картинки
    maxWidth: {
      type: [String, Number],
      default: 170
    },
    // Максимальная высота картинки
    maxHeight: {
      type: [String, Number],
      default: 170
    },
    // Допустимые расшырения файлов
    fileExtensions: {
      type: Array,
      default: () => ["jpg", "png", "jpeg", "gif", "svg", "webp"]
    },
    // Допустимые расшырения файлов
    fileMaxSize: {
      type: Number,
      default: 1024 * 1024 * 10 
    },
    // Базовый урл для изображения
    imageBaseUrl: {
      type: String,
      default: ''
    }
  },

  model: {
    prop: "image",
    event: "image-upload:update"
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.accept = this.fileExtensions.length ? "." + this.fileExtensions.join(",.") : "";
      if (this.image) {
        this.createFile(this.image);
      }
      setTimeout(() => {
        this.restoreImage() 
      }, 500);
    },

    // Создать файл (компоненту v-file-input нужен файл, а не ссылка на файл)
    createFile(src) {
      if (src) {
        this.file = new File([src], src);
      }
      this.imageCur = src;
    },

    // Валидация файла
    validateFile() {
      let isValid = true;
      let ext = this.getExtension(this.file.name);
      if (this.file.size > this.fileMaxSize || this.fileExtensions.length && !this.fileExtensions.includes(ext)) {
        isValid = false;
      }
      return isValid;
    },

    // Получить расшырение файла
    getExtension(name) {
      return name.match(/[^.]+$/i)[0];
    },

    // Сообщение об изменении поля картинки
    emitMessage() {
      this.$emit("image-upload:change", 
        Object.assign(this.preparationQueryParams({}), 
        { sendImage: this.sendImage })
      );
    },

    // Востановить изображение (можно это сделать до удаления)
    restoreImage() {
      this.createFile(this.image);
      this.emitMessage();
    },

    // Очистить ошибку
    clearError() {
      this.error = '';
    },

    // Обновить картинку в форме
    setImage() {
      this.clearError();
      if (!this.file) {
        this.imageCur = '';
        this.emitMessage();
        return;
      }

      if (!this.validateFile()) {
        this.error = `
          Допустимі формати файлів ${this.accept}. 
          Максимальний розмір файлу ${(this.fileMaxSize/1024/1024).toFixed(2)}Мб`;
        return;
      }
      
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.imageCur = reader.result;
        this.emitMessage();
      };
      reader.onerror = () => {
        this.error = "Помилка при створенні прев'ю";
      };
    },

    // Подготовка FormData
    preparationFormData(data) {
      let formData = new FormData();
      for(let key in data) {
        formData.append(key, data[key]);
      }
      return formData;
    },

    // Подготовка параметров запроса
    preparationQueryParams(queryParams) {
      let params = queryParams;
      let action = '';
      
      // Добавить изображение
      if (!this.image && this.imageCur) {
        action = 'add-image';
        params = this.preparationFormData(Object.assign(params, { image:  this.file }));
      }
      // Обновить изображение
      if (this.image && this.imageCur && this.image !== this.imageCur) {
        action = 'update-image';
        params = this.preparationFormData(Object.assign(params, { image:  this.file }));
      }
      // Удалить изображение
      if (this.image && !this.imageCur) {
        action = 'delete-image';
      }

      return {
        params,
        action
      }
    },

    // Отравка изображения на сервер
    /*
      В queryParams необходимо передать такие параметры
      "lang_key": "локалізація",
      "object_image_id": "id", 
      "image_type": "тип"
    */
    async sendImage(queryParams) {
      let response = {};
      let query = this.preparationQueryParams(queryParams);
      if (!query.action || this.isSending) return { error: "Невідома помилка" };
      this.isSending = true;
      this.clearError();
      try {
        let { data } = await HTTP.post(`/${query.action}`, query.params);
        response = data.content;
        this.imageCur = response.fileName ? `${this.imageBaseUrl}${response.fileName}` : '';
        this.createFile(this.imageCur);
        this.$emit("image-upload:update", this.imageCur);
      } catch(error) {
        this.error = "Помилка при завантаженні зображення";
        response = { error: `${this.error} - мова: "${queryParams.lang_key}", мітка: "${this.label}"` };
      } finally {
        this.isSending = false;
      }
      return response;
    }
  },
}
</script>

<style lang="sass">
.image-upload
  position: relative
  &__box-preview
    display: inline-flex
    align-items: center
    justify-content: center
    position: relative
    box-shadow: 0 0 2px rgba(0,0,0,0.5)
    margin-left: 2px
  &__btn-restore
    position: absolute !important
</style>
